<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="85"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item label="选择入库单位" label-width="100px" slot="intoSupplierId">
        <el-select
          v-model="intoSupplierId"
          placeholder="请选择"
          filterable
          clearable
          :filter-method="dataFilter"
          @focus="dataFocus"
        >
          <el-option
            v-for="item in exchangeUnitList2"
            :key="item.id"
            :label="item.exchangeUnitName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="入库单号" slot="intoNo" style="margin: 0" label-width="70px">
        <el-input v-model="intoNo" :clearable="true" size="mini" placeholder="请输入入库单号"></el-input>
      </el-form-item>
      <el-form-item class="el_from" label="制单时间" slot="operationSelect3" style="margin: 0">
        <el-date-picker style="margin-left: 10px;" v-model="entryTime"  :picker-options="pickerOptions" size="small"
              type="daterange" value-format="timestamp" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期" clearable :default-time="['00:00:00', '23:59:59']" >
            </el-date-picker>
      </el-form-item>
      <el-form-item label="制单人" label-width="70px" slot="userId">
        <el-select
          v-model="userId"
          placeholder="请选择"
          filterable
          clearable
        >
          <el-option
            v-for="item in operatorList"
            :key="item.id"
            :label="item.staffName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="库存状态" label-width="70px" slot="stockState">
        <el-select
          v-model="stockState"
          placeholder="请选择"
          filterable
          clearable
        >
          <el-option
            v-for="item in stateList"
            :key="item.id"
            :label="item.status"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </GlobalForm>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";

export default {
  name: "Form",
  data() {
    return {
      day60: 30 * 24 * 3600 * 1000,
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          if (minDate && this.pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            return (
              time.getTime() > this.pickerMinDate + this.day60 ||
              time.getTime() < this.pickerMinDate - this.day60
            );
          }
          return false;
        },
      },
      entryTime: [ new Date(moment().subtract(30, 'day').startOf('day').format("YYYY-MM-DD")+" "+'00:00:00'),
      new Date( moment().endOf('day').format("YYYY-MM-DD")+' '+"23:59:59")],
      initData: null,
      intoNo:"",
      formItemList: [
        { slotName: "intoSupplierId" },
        { slotName: "intoNo" },
        // {
        //   key: "intoNo",
        //   type: "input",
        //   labelName: "入库单号",
        //   placeholder: "请输入入库单号",
        //   lableWidth: "70px",
        // },
        {
          key: "imei",
          type: "input",
          labelName: "旧机串号",
          placeholder: "请输入旧机串号",
          lableWidth: "70px",
        },
        {
          key: "recycleOrderNo",
          type: "input",
          labelName: "订单号",
          placeholder: "请输入订单号",
          lableWidth: "70px",
        },
        {
          key: "barCode",
          type: "input",
          labelName: "旧机条码",
          placeholder: "请输入旧机条码",
          lableWidth: "75px",
        },
        {
          key: "expressNo",
          type: "input",
          labelName: "订单快递单号",
          placeholder: "请输入订单快递单号",
          lableWidth: "105px",
        },
        {
          key: "expressNoExpand",
          type: "input",
          labelName: "备注快递单号",
          placeholder: "请输入备注快递单号",
          lableWidth: "100px",
        },
        // {
        //   key: "entryTime",
        //   type: "daterange",
        //   labelName: "制单时间",
        //   valueFormat: "timestamp",
        //   lableWidth: "80px",
        // },
        { slotName: "operationSelect3" },
        { slotName: "userId" },
        {
          key: "intoRemark",
          type: "input",
          labelName: "入库备注",
          placeholder: "请输入备注内容查询",
          lableWidth: "70px",
        },
        { slotName: "stockState" },//库存状态
      ],
      intoSupplierId: "",
      exchangeUnitList: [],
      exchangeUnitList2: [],
      SeachParams: {},
      userId: "",
      stockState:"",
      operatorList: [],
      stateList: [
        { id:'00',status:'在库中'},
        { id:'01',status:'已出库'}
      ],
      startTime:"",
      endTime:""
    };
  },
  created() {
    this.intoNo=this.$route.query.intoNo
    this.operatorSelect();
    this.supplierIntoSelect()
    this.handleConfirm();
    console.log(this.entryTime);
  },
  methods: {
    handleConfirm(data) {
      console.log(data);
      if (this.entryTime==null) {
        (this.startTime = ""), (this.endTime = "");
      } else {
        (this.startTime = moment(this.entryTime[0]).format("x")),
          (this.endTime = moment(this.entryTime[1]).format("x"));
      }
      const SeachParams = {
        imei:data && data.imei?data.imei : "",
        intoNo: this.intoNo || "",
        intoSupplierId: this.intoSupplierId || '',
        recycleOrderNo: data && data.recycleOrderNo?data.recycleOrderNo : "",
        barCode: data && data.barCode?data.barCode : "",
        expressNo: data && data.expressNo?data.expressNo : "",
        expressNoExpand: data && data.expressNoExpand?data.expressNoExpand : "",
        intoRemark: data && data.intoRemark?data.intoRemark : "",
        userId: this.userId || '',
        stockState:this.stockState || '',
        startTime:this.startTime,
        endTime: this.endTime,
        pageNum: 1,
        pageSize: 10,
      };
      this.SeachParams = SeachParams;
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    // 获取制单人下拉
    operatorSelect() {
      // 暂时
      _api.erpStaffSimpleList({merchantId:this.$route.query.merchantId}).then((res) => {
        if (res.code == 1) {
          this.operatorList = res.data;
        }
      });
    },
    // 获取入库单位列表
    supplierIntoSelect() {
      // // 暂时
      _api.erpMerchantPull({merchantId:this.$route.query.merchantId}).then(res => {
        if(res.code == 1){
          this.exchangeUnitList = res.data
          this.exchangeUnitList2 = res.data
        }
      })
    },
    // // 获取关联回收商户--不用了
    // recycleSelect() {
    //   _api.recycleSelect().then((res) => {
    //     if (res.code == 1) {
    //       this.merchantList = res.data;
    //     }
    //   });
    // },
    dataFocus(){
      this.exchangeUnitList2 = this.exchangeUnitList;
    },
    dataFilter(val) {
      if (val) { //val存在
        this.exchangeUnitList2 = this.exchangeUnitList.filter((item) => {
          if (item.exchangeUnitName.indexOf(val)>-1 || item.dictionary.indexOf(val)>-1 || item.dictionary.toUpperCase().indexOf(val.toUpperCase())>-1) {
            return true
          }
        })
      } else { //val为空时，还原数组
        this.exchangeUnitList2 = this.exchangeUnitList;
      }
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
}
</style>

<template>
  <div class="GlobalTable">
    <div class="flex flex-jb list-top">
      <div class="flex">
        <img style="width: 20px; height: 20px; margin-right: 6px;" src="@/assets/images/iocn_ddlb@2x.png" alt />
        <span>数据列表</span>
      </div>
      <div>
        <el-button size="small" type="success" icon="el-icon-download" @click="clickExcel">导出Excel</el-button>
        <!-- <el-button size="small" type="primary" icon="el-icon-plus" @click="createOutbound">旧机入库</el-button> -->
      </div>
    </div>
    <GlobalTable ref="GlobalTable" v-loading="loading" :columns="tableColumns" :data="seachDataList"
      :currentPage="page.pageNum" :total="page.total" @handleCurrentChange="handleCurrentChange">
      <el-table-column label="旧机名称" slot="phoneName" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.phoneName || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="旧机串号" slot="imei" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.imei || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="绑定条码" slot="barCode" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.barCode || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="入库单位" slot="intoSupplierName" align="center">
        <template slot-scope="{ row }">
          <el-tooltip v-if="row.intoSupplierName" class="item" effect="dark" :content="row.intoSupplierName"
            placement="top">
            <div class="Remarks">{{ row.intoSupplierName }}
            </div>
          </el-tooltip>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="制单人" slot="intoUserName" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.intoUserName }}-{{ row.intoUserPhone }}</span>
        </template>
      </el-table-column>
      <el-table-column label="关联订单号" slot="recycleOrderNo" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.recycleOrderNo" @click="copy(row.recycleOrderNo)"
            style="color:#2384fc;cursor: pointer;">{{ row.recycleOrderNo }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="成交回收商" slot="ackMerchantName" align="center">
        <template slot-scope="{ row }">
          <el-tooltip v-if="row.ackMerchantName" class="item" effect="dark" :content="row.ackMerchantName"
            placement="top">
            <!-- row.isCurrentMerchantAck?row.ackMerchantName:'非您商户，无法查看' -->
            <div class="Remarks">{{row.ackMerchantName}}
            </div>
          </el-tooltip>
          <span v-else>--</span>
        </template>
        <!-- <template slot-scope="{ row }">
          <div v-if="row.isCurrentMerchantAck">
            <el-tooltip v-if="row.ackMerchantName" class="item" effect="dark" :content="row.ackMerchantName"
              placement="top">
              <div class="Remarks">{{ row.ackMerchantName }}</div>
            </el-tooltip>
            <span v-else>--</span>
          </div>
          <span v-else>非您商户，无法查看</span>
        </template> -->
      </el-table-column>
      <el-table-column label="订单快递单号" slot="expressNo" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.expressNo || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="实际快递是否一致" slot="expressIsTrue" align="center">
        <template slot-scope="{ row }">
          <span :style="{ color: !row.expressIsTrue ? 'red' : '' }">{{ row.expressIsTrue ? '是' : '否' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="实际快递单号" slot="expressNoExpand" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.expressNoExpand || '--' }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="备注" slot="intoRemark" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.intoRemark || '--' }}</span>
        </template>
      </el-table-column> -->
      <el-table-column
        label="备注" slot="intoRemark" align="center">
        <template slot-scope="{ row }">
          <el-tooltip
            v-if="row.intoRemark"
            class="item"
            effect="dark"
            :content="row.intoRemark"
            placement="top"
          >
            <div class="Remarks2">
              {{ row.intoRemark}}
            </div>
          </el-tooltip>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <el-table-column label="库存状态" slot="stockState" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.isInHouse?'在库中':'已出库' }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" slot="operation" width="203px" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" round type="warning" @click="printBatchNo(row)">打印串号</el-button>
          <el-button size="mini" round type="primary" @click="warehouseInfo(row)">单据详情</el-button>
        </template>
      </el-table-column> -->
    </GlobalTable>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import _api from "@/utils/request";
// import { getLodop } from "@/utils/LodopFuncs";
export default {
  name: "Table",
  props: {},
  data() {
    return {
      loading: false,
      seachDataList: [],
      defaultShow: false,
      butLoading: false,
      defaultTitle: "",
      enableTitle: "",
      enableShow: false,
      isEnable: false,
      rowObj: {},
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      tableColumns: [
        { label: "入库单据", prop: "intoNo" },
        { slotName: "phoneName" },
        { slotName: "imei" },
        { slotName: "barCode" },
        { label: "入库仓库", prop: "intoWarehouseName" },
        { slotName: "intoSupplierName" },
        { slotName: "intoUserName" },
        { label: "制单时间", prop: "intoTime" },
        { slotName: "recycleOrderNo" },
        { slotName: "ackMerchantName" },
        { slotName: "expressNo" },
        { slotName: "expressIsTrue" },
        { slotName: "expressNoExpand" },
        { slotName: "intoRemark" },
        { slotName: "stockState" },
        { slotName: "operation" },
      ],
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
  },
  methods: {
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        imei:this.SeachParams.imei||"",
        intoRemark: this.SeachParams.intoRemark || "",
        imei:this.SeachParams.imei||"",
        intoNo: this.SeachParams.intoNo || "",
        intoSupplierId: this.SeachParams.intoSupplierId || "",
        recycleOrderNo: this.SeachParams.recycleOrderNo || "",
        barCode: this.SeachParams.barCode || "",
        expressNo: this.SeachParams.expressNo || "",
        expressNoExpand: this.SeachParams.expressNoExpand || "",
        userId: this.SeachParams.userId || "",
        stockState: this.SeachParams.stockState || "",//库存状态
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        merchantId:this.$route.query.merchantId
      };
       // 暂时
      this.loading = true;
      _api.erpMerchantList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.loading = false;
        }
      });
    },
    // 旧机入库点击
    createOutbound() {
      this.$router.push("/warehousManage/createOutbound");
    },
    clickExcel() {
      const params = {
        ...this.SeachParams,
        merchantId:this.$route.query.merchantId
      };
      // 暂时
      _api
        .erpMerchantExcel(params)
        .then((res) => {
          const content = res;
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            //
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                this.$message.error(data.msg || "导出失败");
              }
            } catch (err) {
              let start = moment(Number(params.startTime)).format("YYYY.MM.DD");
              let end = moment(Number(params.endTime)).format("YYYY.MM.DD");
              const fileName = `【入库单据列表】${start || ""}${end ? "-" + end : ""
                }.xlsx`;
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8"); // 设置读取的数据以及返回的数据类型为utf-8
        })
        .catch((err) => {
          console.log(err);
        });
    },
    warehouseInfo(row) {
      let routeData = this.$router.resolve({
        path: "/warehousManage/createOutbound",
        query: { intoNo: row.intoNo },
      });
      window.open(routeData.href, "_blank");
    },
    copy(val) {
      const oInput = document.createElement("input");
      oInput.value = val;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$message.success("复制成功");
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {

  // 备注说明
  .Remarks2 {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4; //控制行数
    overflow: hidden;
  }

  .operation-btn {
    /deep/.el-button {
      margin-left: 0;
    }
  }

  .tableBtn {
    color: #0981ff;
    cursor: pointer;
  }

  .enable-tip {
    color: #ff8080;
    font-size: 14px;
    margin-bottom: 20px;

    .tip-sure {
      margin-top: 10px;
      color: #333333;
      font-size: 16px;
      text-align: center;
    }
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .pc-form {
    margin-top: 20px;
    display: flex;
    align-items: center;

    /deep/.el-input {
      flex: 1;
    }
  }

  .list-top {
    margin-bottom: 20px;
    color: #333333;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-je {
    display: flex;
    justify-content: flex-end;
  }

  .flex-jb {
    display: flex;
    justify-content: space-between;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  /deep/.el-img-upload .el-upload {
    background-color: #f5f6fc;
  }
}
</style>
